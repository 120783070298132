import {Injectable, Inject} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UrlService} from './url.service';
import {ENVIRONMENT_CART_SERVICES_URL} from '@shared/lib/shared.tokens';
import {FloatingPresence} from '@shared/lib/model/floating-presence';
import { Apollo, gql } from 'apollo-angular';
import { Observable, lastValueFrom, map } from 'rxjs';

@Injectable()
export class FloatingPresenceService {
  countryCode = '';
  constructor(private http: HttpClient, private urlService: UrlService,
              @Inject(ENVIRONMENT_CART_SERVICES_URL) private readonly environmentCartServicesUrl: string, private readonly apollo: Apollo) {

  }

  loadFloatingPresence(): Promise<FloatingPresence> {
    return new Promise<FloatingPresence>((resolve, reject) => {
      if (this.seemsLikeSubdomain()) {
        lastValueFrom(this.http.get(`/mvc/floatingPresence/${this.urlService.getSubdomain()}/get`))
          .then((fp: FloatingPresence) => {
            resolve(fp);
            this.countryCode = fp.country;
          });
      } else {
        resolve(new FloatingPresence());
      }
    });
  }

  getEnrollLink(): Observable<string> {
    const query = this.apollo.watchQuery({
      query: gql`
      query allowAssociateSignup($countryCode: String) {
        allowAssociateSignup(countryCode: $countryCode)
    }`,
      variables: { countryCode: this.countryCode },
    }).valueChanges;
    return query.pipe(map((r: any) => {
      return `/mvc/floatingPresence/${this.urlService.getSubdomain()}/enroll?allowAssociateSignup=${r.data.allowAssociateSignup}`;
    }));
  }

  seemsLikeSubdomain(): boolean {
    return this.urlService.getSubdomain() !== 'www';
  }

  loadFeaturedProducts(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (this.seemsLikeSubdomain()) {
        lastValueFrom(this.http.get(`${this.environmentCartServicesUrl}/featuredProductList/landing/`, {withCredentials: true}))
          .then((featured: any) => {
            resolve(featured);
          });
      } else {
        resolve({});
      }
    });
  }

  loadProduct(usanaId): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (this.seemsLikeSubdomain()) {
        lastValueFrom(this.http.get(`${this.environmentCartServicesUrl}/productGroup/byProduct/${usanaId}`, {withCredentials: true}))
          .then((product: any) => {
            resolve(product);
          });
      } else {
        resolve({});
      }
    });

  }
}
